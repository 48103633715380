import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "agreements"
    }}>{`Agreements`}</h1>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`The Agreement Service ("agreements") is a service for storing and retrieving agreements between organisations referred to by Entur services.
It is dependent on Organisation Register and can create permission agreements in Permission Store.`}</p>
    <h2 {...{
      "id": "model"
    }}>{`Model`}</h2>
    <p><img alt="Domain model" src={require("./img/model.png")} /></p>
    <h3 {...{
      "id": "agreement"
    }}>{`Agreement`}</h3>
    <p>{`The agreement represents a agreement between two organisations / Agreement Parties.`}</p>
    <h3 {...{
      "id": "agreement-party"
    }}>{`Agreement Party`}</h3>
    <p>{`An agreement party is an organisation that is part of an agreement. The organisation has to be registered in the Organisation Register.
An Agreement Party has a Party Type`}</p>
    <h3 {...{
      "id": "agreement-detail"
    }}>{`Agreement Detail`}</h3>
    <p>{`The agreement detail contains the extra details of the Agreement pertaining to the agreement Type.
For example, it could be the sales channel for a Sales Agreement.`}</p>
    <h3 {...{
      "id": "agreement-party-detail"
    }}>{`Agreement Party Detail`}</h3>
    <p>{`The agreement party detail contains the extra details of the Agreement Party pertaining to the agreement Type.`}</p>
    <h3 {...{
      "id": "agreement-status"
    }}>{`Agreement Status`}</h3>
    <p>{`The status of the agreement can be one of the following:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`DRAFT`}</inlineCode>{` - The agreement is in draft mode and not yet active.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ACTIVE`}</inlineCode>{` - The agreement is active and can be used.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`DEPRECATED`}</inlineCode>{` - The agreement has been deprecated (and it was never active).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`TERMINATED`}</inlineCode>{` - The agreement has been terminated (it was once active).`}</li>
    </ul>
    <h3 {...{
      "id": "agreement-party-type"
    }}>{`Agreement Party Type`}</h3>
    <p>{`The type of the Agreement Party can be one of the following:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`OWNER`}</inlineCode>{` - The owner of the agreement`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`OPERATOR`}</inlineCode>{` - The operator of the agreement (e.g. a transport operator)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`SELLER`}</inlineCode>{` - The seller of the agreement (e.g. an organisation authorized to sell fares)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`RECIPIENT`}</inlineCode>{` - The recipient of some rights granted by an agreement`}</li>
    </ul>
    <h1 {...{
      "id": "agreement-adapter"
    }}>{`Agreement Adapter`}</h1>
    <p>{`There is also an adapter API that has endpoints for transforming the agreements to a format that can be used by the Entur services, based on their context.
As of now the adapter API has endpoints for transforming agreements to the following formats:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`TransModel NeTEx`}</inlineCode>{` - The format used by the NeTEx model`}</li>
    </ul>
    <h2 {...{
      "id": "usage-context"
    }}>{`Usage Context`}</h2>
    <p>{`In the context of TransModel, there are some specific transformations that are done to the agreements:`}</p>
    <p>{`Agreement Party Type is transformed into the `}<inlineCode parentName="p">{`roles`}</inlineCode>{` field in the TransModel, one of OrganisationTypeEnumeration:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`AUTHORITY`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`OPERATOR`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`OTHER`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "usage-context-1"
    }}>{`Usage Context`}</h2>
    <p>{`Agreements API is maintained by Team Avtale and is used to store and retrieve agreements.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      